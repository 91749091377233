var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "baidu-map",
    {
      staticClass: "suppliers-map",
      attrs: {
        id: "suppliersMap",
        center: _vm.center,
        "scroll-wheel-zoom": _vm.scrollWheelZoom,
        zoom: _vm.zoom,
        ak: _vm.ak,
        mapStyle: _vm.mapStyle,
      },
      on: { zoomend: _vm.zoomend, ready: _vm.ready, load: _vm.load },
    },
    [
      _c("bm-navigation", { attrs: { anchor: "BMAP_ANCHOR_TOP_RIGHT" } }),
      _vm._v(" "),
      _c(
        "bml-marker-clusterer",
        { attrs: { averageCenter: true, styles: _vm.clustererStyles } },
        _vm._l(_vm.markers, function (marker) {
          return _c("bm-marker", {
            key: marker.id,
            attrs: { position: marker.position, icon: _vm.markerStyle },
            on: {
              click: function ($event) {
                return _vm.markerClick(marker)
              },
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "bm-info-window",
        {
          attrs: {
            position: _vm.infoWindow.position,
            width: _vm.infoWindow.width,
            show: _vm.infoWindow.show,
          },
          on: {
            close: function ($event) {
              return _vm.infoWindowClose()
            },
            open: function ($event) {
              return _vm.infoWindowOpen()
            },
          },
        },
        [
          _vm.infoWindow.show
            ? _c("div", { staticClass: "map-popup" }, [
                _vm.supplier.coverPhoto
                  ? _c("img", {
                      staticClass: "map-popup__img",
                      attrs: {
                        src: _vm.imagePath(
                          "images/" + _vm.supplier.coverPhoto,
                          960,
                          250
                        ),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "map-popup__content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "map-popup__title",
                      attrs: { title: _vm.supplier.title },
                    },
                    [_vm._v(_vm._s(_vm.supplier.title))]
                  ),
                  _vm._v(" "),
                  _vm.supplier.footwearTypes
                    ? _c("p", { staticClass: "map-popup__text" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.supplier.footwearTypes
                                .map(function (ft) {
                                  return ft.title
                                })
                                .join(", ")
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.supplier.processes
                    ? _c("p", { staticClass: "map-popup__text" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.supplier.processes
                                .map(function (p) {
                                  return p.title
                                })
                                .join(", ")
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "map-popup__link",
                      on: {
                        click: function ($event) {
                          return _vm.goToSupplier(_vm.supplier.slug)
                        },
                      },
                    },
                    [_vm._v("View info")]
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("bm-fullscreen"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }