<template>
  <baidu-map
    id="suppliersMap"
    class="suppliers-map"
    :center="center"
    :scroll-wheel-zoom="scrollWheelZoom"
    :zoom="zoom"
    :ak="ak"
    @zoomend="zoomend"
    :mapStyle="mapStyle"
    @ready="ready"
    @load="load"
  >
    <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
    <bml-marker-clusterer :averageCenter="true" :styles="clustererStyles">
      <bm-marker
        v-for="marker of markers"
        :key="marker.id"
        :position="marker.position"
        :icon="markerStyle"
        @click="markerClick(marker)"
      ></bm-marker>
    </bml-marker-clusterer>
    <bm-info-window
      :position="infoWindow.position"
      :width="infoWindow.width"
      :show="infoWindow.show"
      @close="infoWindowClose()"
      @open="infoWindowOpen()"
    >
      <div class="map-popup" v-if="infoWindow.show">
        <img
          v-if="supplier.coverPhoto"
          class="map-popup__img"
          :src="imagePath(`images/${supplier.coverPhoto}`, 960, 250)"
        />
        <div class="map-popup__content">
          <div class="map-popup__title" :title="supplier.title">{{ supplier.title }}</div>
          <p v-if="supplier.footwearTypes" class="map-popup__text">
            {{ supplier.footwearTypes.map((ft) => ft.title).join(', ') }}
          </p>
          <p v-if="supplier.processes" class="map-popup__text">
            {{ supplier.processes.map((p) => p.title).join(', ') }}
          </p>
          <span class="map-popup__link" @click="goToSupplier(supplier.slug)">View info</span>
        </div>
      </div>
    </bm-info-window>
    <bm-fullscreen></bm-fullscreen>
  </baidu-map>
</template>

<script>
// TODO: REFACTOR - Remove id - and double check it works properly without!

import { imagePath } from '@lib/imgproxy';
import coordtransform from 'coordtransform';
import BaiduMap from 'vue-baidu-map/components/map/Map.vue';
import BmNavigation from 'vue-baidu-map/components/controls/Navigation.vue';
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue';
import BmInfoWindow from 'vue-baidu-map/components/overlays/InfoWindow.vue';
import BmlMarkerClusterer from 'vue-baidu-map/components/extra/MarkerClusterer.vue';
import BmFullscreen from './baidu-fullscreen-control.vue';

import baiduMapsStyleGetter from 'apps/public/lib/baiduMapsStyleGetter';

const dotMarker = (bg = '#37495e', r = 5) => {
  let encoded = window.btoa(`<svg fill="${bg}" width="${r * 2}" height="${r * 2}" viewBox="0 0 ${r * 2} ${
    r * 2
  }" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <circle cx="${r}" cy="${r}" r="${r}" />
      </svg>`);
  return `data:image/svg+xml;base64,${encoded}`;
};

export default {
  props: ['data', 'countries'],
  components: {
    BaiduMap,
    BmNavigation,
    BmMarker,
    BmInfoWindow,
    BmlMarkerClusterer,
    BmFullscreen,
  },
  data() {
    return {
      scrollWheelZoom: false,
      center: '南宁市', // Nanning City
      // center: '北京', // Beijing City
      zoom: 5,
      ak: APP_CONFIG.baidu.maps.ak,
      mapStyle: { styleJson: baiduMapsStyleGetter() },
      markers: [],
      markerStyle: {
        url: dotMarker('#37495e', 5),
        size: {
          width: 10,
          height: 10,
        },
        anchor: {
          width: 20,
          height: 20,
        },
      },
      clustererStyles: [
        {
          url: dotMarker('#37495e', 12),
          size: {
            width: 24,
            height: 24,
          },
          anchor: {
            width: 12,
            height: 12,
          },
          textColor: 'white',
          textSize: 12,
        },
        {
          url: dotMarker('#37495e', 16),
          size: {
            width: 32,
            height: 32,
          },
          anchor: {
            width: 16,
            height: 16,
          },
          textColor: 'white',
          textSize: 13,
        },
        {
          url: dotMarker('#37495e', 20),
          size: {
            width: 40,
            height: 40,
          },
          anchor: {
            width: 20,
            height: 20,
          },
          textColor: 'white',
          textSize: 14,
        },
      ],
      supplier: null,
      infoWindow: {
        position: null,
        width: 320,
        show: false,
      },
    };
  },
  computed: {
    countriesQuery() {
      if (this.countries) {
        return `Name IN (${this.countries})`;
      } else {
        return null;
      }
    },
  },
  methods: {
    imagePath,
    keydown(event) {
      // Ctrl key pressed
      if (event.keyCode === 17) {
        this.scrollWheelZoom = true;
      }
    },
    keyup(event) {
      // Ctrl key pressed
      if (event.keyCode === 17) {
        this.scrollWheelZoom = false;
      }
    },
    zoomend() {
      this.zoom = this.map.getZoom();
    },
    load() {
      // Set Markers
      this.setMarkers();

      // Highlight selected countries
      this.highlightCountries();

      this.$emit('init');
    },
    ready({ BMap, map }) {
      this.BMap = BMap;
      this.map = map;
    },
    setMarkers() {
      const { BMap, map } = this;

      // Reset/replace markers
      this.markers = this.data.map((item) => {
        const [lng, lat] = coordtransform.gcj02tobd09(...coordtransform.wgs84togcj02(item.longitude, item.latitude));

        return {
          id: item.id,
          position: {
            lng,
            lat,
          },
        };
      });

      // Bound
      if (this.markers.length) {
        map.setViewport(this.markers.map(({ position: { lat, lng } }) => new BMap.Point(lng, lat)));

        // Max zoom
        if (this.zoom > 13) map.setZoom(13);
      }
    },
    async markerClick({ id, position }) {
      const { data: supplier } = await this.$store.suppliers.getOverview(id);

      this.supplier = supplier;
      this.infoWindow.position = position;
      this.infoWindow.width = supplier.coverPhoto ? 320 : 230;
      this.infoWindow.show = true;
    },
    infoWindowClose() {
      this.infoWindow.show = false;
    },
    infoWindowOpen() {
      this.infoWindow.show = true;
    },
    goToSupplier(slug) {
      this.$router.push({ name: 'supplier', params: { name: slug } });
    },
    async highlightCountries() {
      const { BMap, map } = this;

      if (!BMap) return;

      // Clear previous polygons
      map.clearOverlays();

      // Add new ones
      for (const country of this.countries) {
        const codeAlpha3 = this.$store.countries.codeAlpha3FromName(country);
        const polygons = await this.$store.countries.getMultiPolygonCoordinates(codeAlpha3);

        for (const polygon of polygons) {
          // Create instances of Baidu Polygons based on the polygon coordinates from the store
          const overlay = new BMap.Polygon(
            polygon[0].map((coordinates) => {
              // Transform to Baidu positions
              const [lng, lat] = coordtransform.gcj02tobd09(...coordtransform.wgs84togcj02(...coordinates));

              // And instances of Baidu Points
              return new BMap.Point(lng, lat);
            }),
            {
              strokeColor: '#3498DB',
              strokeWeight: 1,
              strokeOpacity: 1,
              strokeStyle: 'solid',
              fillColor: '#3498DB',
              fillOpacity: 0.04,
              enableClicking: false,
            }
          );

          map.addOverlay(overlay);
        }
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.keydown);
    document.addEventListener('keyup', this.keyup);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keydown);
    document.removeEventListener('keyup', this.keyup);
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';

.suppliers-map {
  width: 100%;
  height: 100%;
  margin: 0 auto;

  svg {
    max-height: none;
    max-width: none;
  }
}

.map-popup {
  font-family: 'Lato', sans-serif;
  font-size: math.div(1.2rem, 1.6);
  color: $c-dark;
  position: relative;
  display: flex;
  flex-direction: column;

  &__img {
    display: block;
    width: 100%;
    height: auto;
    flex-shrink: 0;
    background-size: cover;
    background-position: center;
  }
  &__content {
    position: relative;
    width: 100%;
    padding-top: math.div(1rem, 1.6);
    flex-grow: 1;
    & > * {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &__title {
    font-weight: bold;
    font-size: math.div(1.4rem, 1.6);
  }
  &__text {
    margin: math.div(0.4rem, 1.6) 0;
  }
  &__link {
    display: inline-block;
    margin-top: math.div(0.5rem, 1.6);
    border-bottom: 1px solid $c-blue;
    color: $c-blue;
    cursor: pointer;
    transition: border-color 0.5s ease;
    &:hover {
      border-color: transparent;
    }
  }
}
</style>
